<template>
  <div
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <v-app-bar
      v-if="toggleSidebarFromWorkflow"
      app
      fixed
      style="font-weight: bold; background-color: white"
    >
      <img
        v-if="!drawer"
        style="height: 50%"
        src="@/assets/blueJ_logo_blue.svg"
      />
      <v-app-bar-nav-icon @click.stop="toggleSideBar()"></v-app-bar-nav-icon>
      {{ navigationBarTitle[$i18n.locale] }}
      <v-spacer></v-spacer>
      <div style="padding-right: 10px">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 12px;
            gap: 10px;
            width: 52px;
            height: 52px;
            background: #f5f7fb;
            border-radius: 4px;
            cursor: pointer;
          "
          v-if="resourcesObject && resourcesObject.resources && resourcesObject.resources.length > 0"
          @click="getResourcesDialog()"
        >
          <v-img src="@/assets/blueFolder.png"></v-img>

        </div>
      </div>
      <div style="padding-right: 10px">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 12px;
            gap: 10px;
            width: 52px;
            height: 52px;
            background: #f5f7fb;
            border-radius: 4px;
            cursor: pointer;
          "
          @click="getUserlogsDialog()"
        >
          <v-img src="@/assets/userLogs.png"></v-img>
        </div>
      </div>
      <div style="width: 12%; padding-right: 10px">
        <v-autocomplete
          item-text="value"
          item-value="key"
          style="font-size: 15px; font-weight: 500"
          v-model="languageSelected"
          :items="languageArray"
          dense
          outlined
          hide-details
          @change="changeLanguage()"
        ></v-autocomplete>
      </div>
      <v-menu
        offset-y
        bottom
        v-model="notificationMenu"
        :close-on-content-click="false"
        max-width="800px"
        @input="onNotificationMenuToggle"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :value="getNotificationCount"
            :content="getNotificationCount" 
            color="#FF6B00"
            class="mr-5 ml-2 pt-1"
            offset-x="18"
            offset-y="5"
          >
            <v-icon
              v-bind="attrs"
              v-on="on"
            >mdi-bell-outline</v-icon>
          </v-badge> 
        </template>
        <NotificationTabsComponent 
          :notificationsRead="notificationsRead"
          :notificationsUnRead="notificationsUnRead"
          :notificationsBreach="notificationsBreach"
          :newNotificationsCount="newNotificationsCount" 
          @closeNotifications="notificationMenu=false"
          @markAsRead="markAsRead"
          @markAllAsRead="markAllAsRead"
        ></NotificationTabsComponent>
      </v-menu>
      <v-menu right bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            style="
              background-color: #e2e8ff;
              padding: 5px 3px 3px 20px;
              color: black;
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 64px;
            "
            v-bind="attrs"
            v-on="on"
            class="pr-4"
          >
            <div>
              <img
                v-if="authType === 'gauth'"
                :src="googleUserProfile"
                style="
                  border-style: none;
                  border-radius: 100px;
                  height: 45px;
                  width: 45px;
                "
              />
              <v-avatar v-else color="indigo">
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>
            </div>
            <div class="pl-4">
              <v-list-item-title
                style="font-style: normal !important; font-weight: 400"
                >{{ userName }}</v-list-item-title
              >
              <v-list-item-subtitle
                style="font-style: normal !important; font-weight: 400"
                >{{ userGroup }}</v-list-item-subtitle
              >
            </div>
            <v-icon class="pl-5"> mdi-chevron-down </v-icon>
          </div>
        </template>

        <v-card>
          <v-list>
            <v-list-item @click="clickLogout()">
              <v-icon color="#2A409A">mdi-logout</v-icon>
              <v-list-item-content class="pl-5">
                <v-list-item-title> Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      color="#2A409A"
      hide-overlay
      :permanent="isPermanent"
      :app="isApp"
      :obsolute="isObsolute"
      :temporary="isTemporary"
      v-if="drawer"
    >
      <v-divider></v-divider>
      <v-list
        nav
        dense
        class="pb-2"
        style="
          position: sticky;
          top: 0px;
          z-index: 10;
          background-color: rgb(42, 64, 154);
        "
      >
        <v-list-item>
          <img
            src="../assets/Frame 3.svg"
            style="width: 100%; height: 100%; padding-left: 70px"
            class="pointer"
          />
          <!-- <v-icon color="white" @click.stop="toggleSideBar()">mdi-chevron-left-box</v-icon> -->
        </v-list-item>
        <v-divider></v-divider>
      </v-list>

      <v-list
        nav
        dense
        style="height: 93vh; overflow-y: scroll"
        class="sidebarItems"
      >
        <!-- color="indigo" -->
        <v-list-item-group
          v-model="sideBarListModel"
          active-class="sideBarList"
          :mandatory="mandatory"
        >
          <!-- background-color="#2a409a" -->
          <v-list-item
            v-for="(item, index) in listofApplication"
            :key="item.id"
            :disabled="lockSelection"
            style="color: #ffffff"
          >
            <!-- <v-list-item-icon>
            <img src="../assets/Icon.png" />
            <v-icon color="white" small>mdi-eye</v-icon> -->
            <!-- </v-list-item-icon> -->
            <v-list-item-content
              active-class="sideBarList"
              :mandatory="mandatory"
              @click="clickApplication(item.id, index)"
            >
              <v-list-item-title
                class="pl-12 pt-5 pb-2"
                style="display: flex; direction: row; justify-items: center"
              >
                {{ item.name[$i18n.locale] }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div v-if="sessionValue">
      <RedirectLogin @redirectPage="redirectPage"></RedirectLogin>
    </div>
    <div v-if="logOutbutton">
      <LogOutDialogBox @closeLogOutDialog="closeLogOutDialog" @loggedOut="loggedOut"></LogOutDialogBox>
    </div>
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
    <div v-if="userlogsDialog" style="height: 100%">
      <UserLogsDialog
        :userlogsDialog="userlogsDialog"
        :listofApplication="listofApplication"
        :userlogsData="userlogsData"
        @closeUserLogsDialog="closeUserLogsDialog"
      >
      </UserLogsDialog>
    </div>
    <div v-if="resourcesDialog">
      <ResourcesDialog
        :resourcesDialog="resourcesDialog"
        :resourcesObject="resourcesObject"
        @closeResourcesDialog="closeResourcesDialog"
      >
      </ResourcesDialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RedirectLogin from "../components/RedirectLogin.vue";
import LogOutDialogBox from "../components/LogOutDialogBox.vue";
import MessageDialog from "./MessageDialog.vue";
import UserLogsDialog from "./UserLogsDialog.vue";
import store from '../store';
import NotificationTabsComponent from "./NotificationTabsComponent.vue";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface";
import ResourcesDialog from "./ResourcesDialog.vue";
const backendApi = axios.create({ baseURL: process.env.VUE_APP_BACKEND_URL })
export default {
  props: [
    "navigationBarTitle",
    "userGroup",
    "lockSelection",
    "sidebarToggleFlag",
    "sidebarToggleWithDrawer",
    "resourcesObject",
  ],
  components: {
    RedirectLogin,
    LogOutDialogBox,
    UserLogsDialog,
    MessageDialog,
    ResourcesDialog,
    NotificationTabsComponent
  },
  data() {
    return {
      listofApplication: [],
      resourceFlag: false,
      showNotifications:false,
      resourcesDialog: false,
      googleUserProfile: "",
      allowedListOfApplications: [],
      UserId: "",
      userName: "",
      languageArray: [
        {
          key: "en",
          value: "English",
        },
        {
          key: "mm",
          value: "Burmese",
        },
      ],
      // languageArray: ["en", "mm"],
      languageSelected: "en",
      sideBarListModel: -1,
      sessionValue: false,
      authType: "",
      userlogsDialog: false,
      userlogsData: [],
      mandatory: false,
      logOutbutton: false,
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      activeTimeout: null,
      gAuthRefreshCount: 1,
      gAuthListener: "",
      userChanged: (user) => {
        if (user) {
          let basicProfile = user.getBasicProfile();
          var email = basicProfile.getEmail();
          setToLocalStorage("userId", email);
          var fullName = basicProfile.getName();
          setToLocalStorage("fullName", fullName);
          var loginImage = basicProfile.getImageUrl();
          setToLocalStorage("loginImage", loginImage);
        }
        if (this.gAuthListener) this.gAuthListener.remove();
      },
      isPermanent: true,
      isApp: true,
      isTemporary: false,
      isObsolute: false,
      drawer: true,
      sidebarToggle: this.sidebarToggleFlag,
      toggleSidebarFromWorkflow: true,
      pingRefresh: null,
      notificationMenu: false,
      newNotificationsCount: 0,
    };
  },
  computed: {
    notificationsRead() {
      return this.$store.state.notificationsRead
    },
    notificationsUnRead() {
      return this.$store.state.notificationsUnRead
    },
    notificationsBreach() {
      return this.$store.state.notificationsBreach
    },
    getNotificationCount() {
      let unreadCount = this.notificationsUnRead.notifications.length;
      let breachCount = this.notificationsBreach.notifications.length;
      
      if (this.notificationsBreach.lastEvaluatedKey === '' && this.notificationsUnRead.lastEvaluatedKey === '') {
        return unreadCount + breachCount;
      } else {
        return `${unreadCount + breachCount}+`;
      }
    }
  },
  watch: {
    sidebarToggleFlag: {
      handler: function (after, before) {
        if (!this.sidebarToggleFlag) {
          if (this.drawer) {
            this.toggleSideBar();
          }
        } else {
          if (!this.drawer) {
            this.toggleSideBar();
          }
        }
      },
    },
  },
  methods: {
    async markAsRead(index, notificationId, type) {
      const requestData = {
        token:  getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        notificationIds: [notificationId]
      }
      try {
        await backendApi.post('/readNotifications', requestData);
        this.$store.dispatch("markAsRead", {'type': type, 'index': index});
      } catch (error) {
        if (error.response && error.response.status && error.response.status == 401) {
          this.$root.$emit("update_session_value", true);
        } else {
          this.errorMessageShow = true;
          let errorMessageObject = {
            errorMessageShow: this.errorMessageShow,
            error_code: "",
            error_uuid: "",
          }
          if (error.response && error.response.data && error.response.data.errorObject) {
            errorMessageObject['error_code'] = error.response.data.errorObject.error_code;
            errorMessageObject['error_uuid'] = error.response.data.errorObject.error_uuid;
          }
          this.errorMessageObject = errorMessageObject;
          this.showMessageDialog = true;
        }
      }
    },
    /**
     * 
     * @param {str[]} notificationIds 
     * @param {str} type 
     */
    async markAllAsRead(notificationIds, type) {
      const requestData = {
        token:  getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        notificationIds: notificationIds
      }
      try {
        await backendApi.post('/readNotifications', requestData);
        this.$store.dispatch("markAllAsRead", {'type': type, 'notificationIds': notificationIds});
      } catch (error) {
        if (error.response && error.response.status && error.response.status == 401) {
          this.$root.$emit("update_session_value", true);
        } else {
          if (
            error.response && error.response.status && error.response.status == 400 && 
            error.response.data && error.response.data.NotificationIds
          ) {
            let successNotificationIds = notificationIds.filter(id => !error.response.data.NotificationIds.includes(id));
            this.$store.dispatch("markAllAsRead", {'type': type, 'notificationIds': successNotificationIds});
          }
          this.errorMessageShow = true;
          let errorMessageObject = {
            errorMessageShow: this.errorMessageShow,
            error_code: "",
            error_uuid: "",
          }
          if (error.response && error.response.data && error.response.data.errorObject) {
            errorMessageObject['error_code'] = error.response.data.errorObject.error_code;
            errorMessageObject['error_uuid'] = error.response.data.errorObject.error_uuid;
          }
          this.errorMessageObject = errorMessageObject;
          this.showMessageDialog = true;
        }
      }
    },
    connectToWebSocket() {
      let frontendUrl = window.location.origin;
      let websocketBaseUrl;
      if (frontendUrl === process.env.VUE_APP_FRONTEND_REVERSE_PROXY_URL) {
        websocketBaseUrl = process.env.VUE_APP_WEBSOCKET_REVERSE_PROXY_URL;
      } else {
        websocketBaseUrl = process.env.VUE_APP_WS_URL;
      }
      this.$connect(`${websocketBaseUrl}?userId=${this.UserId}`, {
        store: store,
        reconnection: true, 
      });
      this.startPing();
      let that = this;
      this.$socket.onmessage = function(event) {
        let notification = JSON.parse(event.data);
        that.$store.dispatch("addNewNotification", notification);
      }
    },
    stopPing() {
      clearInterval(this.pingRefresh);
    },
    startPing() {
      this.pingRefresh = setInterval(() => {
        if (this.$socket) {
          this.$socket.send('ping');
        } else {
          this.stopPing();
        }
      }, 9.5*60*1000);
    },
    disconnectFromWebSocket() {
      this.stopPing();
      this.$disconnect();
    },
    loggedOut() {
      clearTimeout(this.activeTimeout);
      this.disconnectFromWebSocket();
    },
    onNotificationMenuToggle(opened) {
      if (!opened) {
        this.newNotificationsCount = 0;
      }
    },
    async getUserlogsDialog() {
      this.userlogsDialog = true;
    },
    openNotifications(){
      this.showNotifications = true;
    },
    closeNotifications(value){
      this.showNotifications = value;
    },
    getResourcesDialog() {
      this.resourcesDialog = true;
    },
    toggleSideBar() {
      (this.isPermanent = !this.isPermanent),
        (this.isApp = !this.isApp),
        (this.isTemporary = !this.isTemporary),
        (this.isObsolute = !this.isObsolute),
        (this.drawer = !this.drawer);
    },
    closeLogOutDialog(flag) {
      this.logOutbutton = flag;
    },
    closeResourcesDialog(emitObject) {
      this.resourcesDialog = emitObject.resourcesDialog;
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if (emitObject && emitObject.errorDisplayMessage) {
        closeDialogFlag = emitObject.errorDisplayMessage;
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    closeUserLogsDialog(emitObject) {
      this.userlogsDialog = emitObject.userlogsDialog;
    },
    changeLanguage() {
      this.$i18n.locale = this.languageSelected;
      this.$store.dispatch("changeLocale", this.languageSelected);
    },

    clickApplication(applicationId, index) {
      this.mandatory = true;
      this.applicationId = this.$route.query.applicationId;
      if (applicationId !== this.$route.query.applicationId) {
        this.$router.push({
          name: "ApplicationDetails",
          path: "/applicationDetails",
          query: { applicationId: applicationId },
        });
        let userIdData = getFromLocalStorage("userId");
        setToLocalStorage(
          "currentPath",
          this.$route.fullPath + "#" + userIdData
        );
      }
      this.$emit("reloadAppScreen");
    },

    clickLogout() {
      this.logOutbutton = true;
    },
    redirectPage() {
      this.sessionValue = false;
      this.reloadSidebar();
      this.$emit("reloadAppScreen");
    },
    reloadSidebar() {
      if (this.$store.state.locale == "") {
        this.$store.dispatch("changeLocale", "en");
      }
      this.languageSelected = this.$store.state.locale;
      this.$i18n.locale = this.$store.state.locale;
      if (getFromLocalStorage("authType")) {
        this.authType = getFromLocalStorage("authType");
      }
      if (getFromLocalStorage("userId")) {
        this.UserId = getFromLocalStorage("userId");
      }
      if (this.authType == "onelogin") {
        this.userName = getFromLocalStorage("userName");
      } else {
        this.userName = getFromLocalStorage("userId");
      }
      if (getFromLocalStorage("loginImage")) {
        this.googleUserProfile = getFromLocalStorage("loginImage");
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/getListOfApplication",
        
        data: {
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then((applicationListResponse) => {
          this.listofApplication = this.getShowFrameWork(applicationListResponse.data.data);
          if (this.$route.query.applicationId) {
            this.sideBarListModel = this.listofApplication.findIndex(
              (app) => app.id === this.$route.query.applicationId
            );
          }
        })
        .catch((applicationListError) => {
          if (
            applicationListError.response &&
            applicationListError.response.status &&
            applicationListError.response.status == 401
          ) {
            this.$root.$emit("update_session_value", true);
          } else {
            this.errorMessageShow = true;
            if (
              applicationListError.response &&
              applicationListError.response.data &&
              applicationListError.response.data.errorObject
            ) {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code:
                  applicationListError.response.data.errorObject.error_code,
                error_uuid:
                  applicationListError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },

    refreshIdToken() {
      if (
        this.authType === "gauth" &&
        getFromLocalStorage("token") &&
        getFromLocalStorage("id_token_exp")
      ) {
        let token = getFromLocalStorage("token");
        axios({
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL + "/verifyUser",
          data: { token: token, authType: "gauth_refresh" },
          headers: {
            "content-type": "application/json",
          },
        })
          .then((verifyUserResponse) => {
            if (verifyUserResponse.data.message !== "Success") {
              this.$root.$emit("update_session_value", true);
              return;
            }
            var id_token = verifyUserResponse.data.id_token;
            var id_token_exp = verifyUserResponse.data.expiry;
            setToLocalStorage("token", id_token);
            setToLocalStorage("id_token_exp", id_token_exp);
            let current_ts = Math.floor(+new Date() / 1000);
            if (this.gAuthRefreshCount < 25) {
              //Refreshing IdToken for around 24 hours (one when mounted + 25) * (55 mins), then let it expire
              this.gAuthRefreshCount++;
              this.activeTimeout = setTimeout(
                this.refreshIdToken,
                1000 * (id_token_exp - current_ts - 60 * 5)
              ); // expiry_time - 5 mins
            }
          })
          .catch((verifyUserError) => {
            this.$root.$emit("update_session_value", true);
            if (
              verifyUserError.response &&
              verifyUserError.response.status &&
              verifyUserError.response.status == 401
            ) {
              this.$root.$emit("update_session_value", true);
            } else {
              this.errorMessageShow = true;
              if (
                verifyUserError.response &&
                verifyUserError.response.data &&
                verifyUserError.response.data.errorObject
              ) {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code:
                    verifyUserError.response.data.errorObject.error_code,
                  error_uuid:
                    verifyUserError.response.data.errorObject.error_uuid,
                };
              } else {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
            }
          });
      } else {
        this.$root.$emit("update_session_value", true);
      }
    },

    getShowFrameWork(data){
      this.allowedListOfApplications = [];
      for (let index = 0; index < data.length; index++) {
        if(data[index].showInFramework && data[index].showInFramework.includes("vuejs")){
          this.allowedListOfApplications.push(data[index])
        }
      }
      return this.allowedListOfApplications;
    }
  },
  beforeDestroy () {
    this.disconnectFromWebSocket();
  },
  mounted() {
    // For RedirectLogin Popup
    this.$root.$on("update_session_value", (value) => {
      this.sessionValue = value;
    });

    if (getFromLocalStorage("authType")) {
      this.authType = getFromLocalStorage("authType");
    }
    // For Gauth id_token refresh
    if (this.authType === "gauth" && getFromLocalStorage("id_token_exp")) {
      let expiry = parseInt(getFromLocalStorage("id_token_exp"));
      let current_ts = Math.floor(+new Date() / 1000);
      this.activeTimeout = setTimeout(
        this.refreshIdToken,
        1000 * (expiry - current_ts - 60 * 5)
      ); // expiry_time - 5 mins
    }

    if (this.$store.state.locale == "") {
      this.$store.dispatch("changeLocale", "en");
    }
    this.languageSelected = this.$store.state.locale;
    this.$i18n.locale = this.$store.state.locale;
    if (getFromLocalStorage("userId")) {
      this.UserId = getFromLocalStorage("userId");
    }
    if (this.authType == "onelogin") {
      this.userName = getFromLocalStorage("userName");
    } else {
      this.userName = getFromLocalStorage("userId");
    }
    if (getFromLocalStorage("loginImage")) {
      this.googleUserProfile = getFromLocalStorage("loginImage");
    }
    let userIdValue = getFromLocalStorage("userId");
    setToLocalStorage(
      "currentPath",
      this.$route.fullPath + "#" + userIdValue
    );
    axios({
      method: "post",
      url: process.env.VUE_APP_BACKEND_URL + "/getListOfApplication",
      data: {
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
      },
      headers: {
        "content-type": "application/json",
      },
    })
      .then((applicationListResponse) => {
        this.listofApplication = this.getShowFrameWork(applicationListResponse.data.data);
        this.$emit("no_of_applications", this.listofApplication.length);
        if (this.$route.query.applicationId) {
          this.sideBarListModel = this.listofApplication.findIndex(
            (app) => app.id === this.$route.query.applicationId
          );
          if (this.listofApplication.length == 1) {
            this.toggleSideBar();
          }
        } else if (this.listofApplication.length == 1) {
          this.$router.push({
            name: "ApplicationDetails",
            path: "/applicationDetails",
            query: { applicationId: this.listofApplication[0].id },
          });
        }
      })
      .catch((applicationListError) => {
        if (
          applicationListError.response &&
          applicationListError.response.status &&
          applicationListError.response.status == 401
        ) {
          this.sessionValue = true;
        } else {
          this.errorMessageShow = true;
          if (
            applicationListError.response &&
            applicationListError.response.data &&
            applicationListError.response.data.errorObject
          ) {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code:
                applicationListError.response.data.errorObject.error_code,
              error_uuid:
                applicationListError.response.data.errorObject.error_uuid,
            };
          } else {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
        }
      });
    
    // make socket connection
    this.connectToWebSocket();
  },
};
</script>
<style>
.sideBarList {
  background-color: rgba(255, 255, 255, 1) !important;
  color: #0054a6 !important;
  /* font-weight: 700 bold !important; */
}

.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: 1px !important;
  padding: 0px !important;
}

.v-list--nav {
  padding: 0px;
}

.v-list-item__title .v-list-item__subtitle {
  font-weight: 400 !important;
  font-style: normal !important;
}

div.v-toolbar__content {
  padding-right: 0px;
}

/* width */
.sidebarItems::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

/* Track */
.sidebarItems::-webkit-scrollbar-track {
  background: rgb(42, 64, 154);
}

/* Handle */
.sidebarItems::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle on hover */
.sidebarItems::-webkit-scrollbar-thumb:hover {
  background: #f2f2f2;
}
</style>